<template>
    <div class="form-contact" v-if="translates && translates[langUrl]">
        <div v-if="!formSended">
            <ValidationObserver ref="validator" slim>
                <ValidationProvider v-slot="{ errors }" rules="required">
                    <BaseTextField
                        v-model="feedback.name"
                        class="form"
                        :placeholder="translates[langUrl].placeholder_name[lang]"
                        :error-messages="errors[0]"
                    />
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" rules="required">
                    <BasePhoneNumberInput
                        v-model="feedback.phone"
                        @update="number($event)"
                        :error-messages="errors[0]"
                    />
                </ValidationProvider>
                <BaseTextField
                    v-model="feedback.email"
                    class="form"
                    :placeholder="translates[langUrl].placeholder_email[lang]"
                />
                <BaseTextArea
                    v-model="feedback.comment"
                    class="form"
                    :placeholder="translates[langUrl].placeholder_comment[lang]"
                />
                <BaseButton
                    class="button-fill"
                    style="width:100%"
                    @click="send">{{ translates[langUrl].button_send[lang] }}</BaseButton>
            </ValidationObserver>
        </div>
        <div v-if="formSended" class="form-question__sended">
            <div class="form-question__sended__text">{{ translates[langUrl].formSended[lang] }}</div>
            <BaseButton
                class="button-fill"
                style="width:100%"
                @click="formReverse"
            >{{ translates[langUrl].button_formReverse[lang] }}</BaseButton>
        </div>
    </div>
</template>
<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import BaseTextField from './BaseTextField.vue';
    import BaseTextArea from './BaseTextArea.vue';
    import BaseButton from './BaseButton.vue';
    import BaseCheckbox from './BaseCheckbox.vue';
    import BasePhoneNumberInput from './BasePhoneInput.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    export default {
        name: 'FormContact',
        components: {
            BaseTextField,
            BaseTextArea,
            BaseButton,
            BaseCheckbox,
            ValidationProvider,
            ValidationObserver,
            BasePhoneNumberInput
        },
        data: () => ({
            feedback: {
                name: '',
                phone: '',
                email: '',
                comment: '',
                link: '',
            },
            phoneWithCode: '',
            formSended: false,
            langUrl: 'FormContact'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        methods: {
            async send() {
                const valid = await this.$refs.validator.validate()
                if(valid) {
                    this.feedback.phone = this.phoneWithCode;
                    this.feedback.link = document.location.href;
                    await store.commit('feedbacks/SET_ENTITY', this.feedback);
                    await store.dispatch('feedbacks/save');
                    await store.commit('feedbacks/CLEAR_ENTITY');
                    await this.clear()
                    await this.formReverse()
                }
            },
            async formReverse() {
                this.formSended = !this.formSended
            },
            async clear () {
                this.feedback = {
                    name: '',
                    phone: '',
                    email: '',
                    comment: '',
                    link: '',
                }
            },
            number(event) {
                this.phoneWithCode = event.formattedNumber
            }
        }
    }

</script>
<style lang="scss">
.form-contact {
    font-family: Inter;
    font-style: normal;
    width: 520px;
    background: #fff;
    border-radius: 12px;
    @media all and (max-width: 768px) {
        width: 100%;
        padding: 0 11px 0 0;
    }
    &__sended {
        margin-top: 50%;
        &__text {
            margin-bottom: 15px;
        }
    }
}
</style>